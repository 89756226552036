import { defineStore } from 'pinia'
import { useState } from './state'
import { useGetters } from './getters'
import useStore from 'store/index'

export const useActions = defineStore('dashboard.actions', () => {
  const state = useState()
  const getters = useGetters()
  const store = useStore()

  const showWelcomeText = () => {
    console.log('getters value', getters.getText)
    if (state.welcomeText) {
      console.log(state.welcomeText)
    }
  }

  const setWelcomeText = (value = '') => {
    state.welcomeText = value
  }

  const setIsSBPin = (value: boolean) => (state.isSBPin = value)
  const setIsSBOpen = (value: boolean) => (state.isSBOpen = value)

  const toggleMenu = () => {
    if (window.innerWidth < 1024) {
      setIsSBOpen(!state.isSBOpen)
    } else {
      setIsSBPin(!state.isSBPin)
    }
  }
  // Remove a site by ID from both recentSites and topSites
  const removeSite = async (id: number, callback: (result: boolean, error?: any) => void) => {
    store.auth
      .makeRequest<any>(`sites/${id}`, { _method: 'DELETE' }, 'POST')
      .then((response) => {
        console.log(response, 'done')
        state.recentSites = state.recentSites.filter((site) => site.id !== id)
        state.topSites = state.topSites.filter((site) => site.id !== id)
        state.allSites = state.allSites.filter((site) => site.id !== id)
        state.analysisData.site_count -= 1

        // Call the callback function with a success flag
        callback(true)
      })
      .catch((error) => {
        console.log(error)
        // Call the callback function with an error message
        callback(false, error)
      })
  }

  return {
    showWelcomeText,
    setWelcomeText,
    setIsSBPin,
    setIsSBOpen,
    toggleMenu,
    removeSite,
  }
})


      // Import the functions you need from the SDKs you need
      import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.8.4/firebase-app.js'
      import { getAnalytics } from 'https://www.gstatic.com/firebasejs/9.8.4/firebase-analytics.js'
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: 'AIzaSyAyis-Y_0dDJcS9-DordwJO2p0Ip8TDH-s',
        authDomain: 'ltv-project-template.firebaseapp.com',
        projectId: 'ltv-project-template',
        storageBucket: 'ltv-project-template.appspot.com',
        messagingSenderId: '322102888634',
        appId: '1:322102888634:web:6896453699479fb4099a83',
        measurementId: 'G-X7XVLW93EV',
      }

      // Initialize Firebase
      const app = initializeApp(firebaseConfig)
      const analytics = getAnalytics(app)
    
import { defineStore } from 'pinia';
import { AuthState } from './types';
import { AUTH_EMAIL, AUTH_TOKEN, AUTH_USER } from 'core/constants';
import { computed, watch, ref } from 'vue';

export const useState = defineStore({
  id: 'auth.state',
  state: (): AuthState => {
    // Create a ref for hasOTP
    const hasOTP = ref(0);

    // Create a computed property to read the value from local storage
    const user = computed(() => {
      const userData = localStorage.getItem(AUTH_USER);
      return userData ? JSON.parse(userData) : null;
    });

    const isAuthenticated = computed(() => !!localStorage.getItem(AUTH_TOKEN));
    const token = computed(() => localStorage.getItem(AUTH_TOKEN));

    // Use watch to update the local storage when the store properties change
    watch(user, (newValue) => {
      localStorage.setItem(AUTH_USER, JSON.stringify(newValue));
    });

    const email = localStorage.getItem(AUTH_EMAIL);

    // Watch hasOTP for changes and update it in the store
    watch(hasOTP, (newHasOTP) => {
      // Perform any additional actions or updates here if needed
    });

    return {
      isAuthenticated: isAuthenticated.value,
      token: token.value,
      hasOTP: hasOTP.value,
      user: user.value,
      email: email,
    };
  },
});

import { defineStore } from 'pinia'
import { DashboardState } from './types'

export const useState = defineStore({
  id: 'dashboard.state',
  state: (): DashboardState => {
    return {
      welcomeText: 'Welcome to Dashboard! ...',
      isSBPin: true,
      isSBOpen: true,
      newSite: false,
      analysisData: { site_count: 0, mt: 0, um: 0, rs: 0 },
      allSites: [],
      recentSites: [],
      topSites: [],
    }
  },
})

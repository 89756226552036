import {
  ViewGridIcon,
  CursorClickIcon,
  DocumentTextIcon,
  StarIcon,
  HomeIcon,
  ViewBoardsIcon,
  BellIcon,
  LocationMarkerIcon,
  UserIcon,
  ColorSwatchIcon,
} from '@heroicons/vue/outline'

import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  CreditCardIcon,
  GlobeAltIcon,
  HeartIcon,
  ShoppingBagIcon,
} from '@heroicons/vue/solid'

const Login = () => import('modules/auth/views/login.vue')
const Register = () => import('modules/auth/views/register.vue')
const ForgotPassword = () => import('modules/auth/views/forgot-password.vue')
const NotFound = () => import('modules/pages/views/404.vue')
const Dashboard = () => import('modules/dashboard/views/index.vue')
const AllSites = () => import('modules/dashboard/views/sites/all.vue')
const FormSites = () => import('modules/dashboard/views/sites/form.vue')
const BusinessSites = () => import('modules/dashboard/views/sites/business.vue')
const EventSites = () => import('modules/dashboard/views/sites/event.vue')
const LeadSites = () => import('modules/dashboard/views/sites/sale.vue')
const WeddingSites = () => import('modules/dashboard/views/sites/wedding.vue')
const AppointmentSites = () => import('modules/dashboard/views/sites/appointment.vue')
const Table = () => import('modules/table/views/index.vue')
const Grid = () => import('modules/grid/views/index.vue')
const Notification = () => import('modules/notification/views/index.vue')
const Button = () => import('modules/buttons/views/index.vue')
const Tags = () => import('modules/tags/views/index.vue')
const Typography = () => import('modules/typography/views/index.vue')
const Card = () => import('modules/cards/views/index.vue')
const Icons = () => import('modules/icons/views/index.vue')
const Profile = () => import('modules/profile/views/index.vue')
const Wizard = () => import('modules/wizard/views/index.vue')

const ComponentLayout = () => import('components/ComponentLayout/index.vue')

const routes = [
  {
    path: '/',
    component: Wizard,
    name: 'wizard',
    meta: {
      title: 'Site Launcher',
      requiresAuth: true,
      isOnboarding: true,
    },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: HomeIcon,
      color: 'text-indigo-410',
      requiresAuth: true,
      parentPath: 'Home',
    },
  },
  {
    path: '/sites/:siteItem?',
    component: ComponentLayout,
    name: 'Sites',
    meta: {
      title: 'Sites',
      icon: GlobeAltIcon,
      color: 'text-info',
      requiresAuth: true,
      parentPath: 'sites',
    },
    children: [
      {
        path: 'all',
        name: 'All',
        component: AllSites,
        meta: {
          title: 'All Sites',
          icon: GlobeAltIcon,
          color: 'text-info',
          requiresAuth: true,
        },
      },
      {
        path: 'portfolio',
        name: 'Portfolio',
        component: BusinessSites,
        meta: {
          title: 'Portfolio',
          icon: BriefcaseIcon,
          color: 'text-danger-50',
          requiresAuth: true,
        },
      },
      {
        path: 'forms',
        name: 'Form',
        component: FormSites,
        meta: {
          title: 'Form',
          icon: HeartIcon,
          color: 'text-primary',
          requiresAuth: true,
        },
      },
      {
        path: 'events',
        component: EventSites,
        name: 'Event',
        meta: {
          title: 'Event',
          icon: CalendarIcon,
          color: 'text-red-410',
          requiresAuth: true,
        },
      },
      {
        path: 'weddings',
        component: WeddingSites,
        name: 'Wedding',
        meta: {
          title: 'Wedding',
          icon: HeartIcon,
          color: 'text-indigo-450',
          requiresAuth: true,
        },
      },
      {
        path: 'sales',
        component: LeadSites,
        name: 'Sales',
        meta: {
          title: 'Sales',
          icon: ShoppingBagIcon,
          color: 'text-orange-light',
          requiresAuth: true,
        },
      },
      {
        path: 'appointments',
        component: AppointmentSites,
        name: 'Appointment',
        meta: {
          title: 'Appointment',
          icon: ClockIcon,
          color: 'text-success-50',
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: '/account',
    component: Profile,
    name: 'Account',
    meta: {
      title: 'Account',
      icon: UserIcon,
      color: 'text-success-50',
      isDarkBackground: true,
      isFullWidthLayout: true,
      requiresAuth: true,
      parentPath: 'Home',
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
    meta: {
      requiresAuth: false,
    },
  },
]

export default routes

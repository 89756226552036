<template>
  <div class="h-screen overflow-hidden flex bg-slate-20 w-full" v-loading.fullscreen.lock="loading">
    <sidebar />
    <div
      class="main-content flex flex-col flex-1 w-full overflow-auto"
      :class="`${!isSBPin ? ' ml-17 ' : 'ml-62.5 cursor-pointer lg:cursor-default'}`"
    >
      <navigation />
      <div
        class="w-full h-38 relative bg-transparent border-none"
        :class="{
          'bg-theme-blue': !route.meta.isDarkBackground,
        }"
      >
        <div
          class="flex items-center py-5 mb-0 px-3.75 pr-3.5 md:px-7.25 md:pl-7.5 pt-6"
          v-if="!route.meta.isDarkBackground"
        >
          <div class="w-full flex flex-wrap flex-row">
            <div class="flex w-1/2">
              <div class="text-white text-xl font-semibold inline-block pt-px">
                {{ route.meta.title }}
              </div>
              <div class="md:ml-7 hidden md:inline-block pt-1.5">
                <BreadCrumb :parentPath="route.meta.parentPath" :title="route.meta.title" />
              </div>
            </div>
            <div class="w-1/2 text-right pt-px pr-8">
              <div class="relative inline-block pt-1">
                <el-popover
                  placement="bottom-end"
                  trigger="click"
                  popper-class="menu-popper"
                  :show-arrow="false"
                  @show="clickIconMenu = !clickIconMenu"
                  @hide="clickIconMenu = !clickIconMenu"
                >
                  <template #reference>
                    <el-icon
                      :size="20"
                      class="cursor-pointer w-4.5 h-4.5 text-default hover:text-slate-300"
                      :class="{
                        'text-primary': clickIconMenu,
                        'text-dark-lighter hover:text-indigo-410': route.meta.isDarkBackground,
                      }"
                    >
                      <el-button
                        size="small"
                        class="w-11.25 h-7 rounded font-semibold text-indigo-410 bg-white border-white hover:text-black hover:bg-white active:bg-slate-100 tracking-wide"
                      >
                        <CirclePlusFilled />&nbsp;Make Site</el-button
                      >
                    </el-icon>
                  </template>
                  <div class="w-full m-0">
                    <div class="flex flex-wrap w-full m-0">
                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.business"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex h-13 w-14 content-center items-center text-center">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all p-3.4 hover:px-4.5 hover:py-4.4 duration-150 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#f5365c] to-[#f56036]"
                              :icon="['fas', 'calendar-days']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-2.5">Portfolio</span>
                      </a>

                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.event"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex h-13 w-14 content-center items-center text-center">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all p-3.4 hover:p-4.4 duration-150 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#fb6340] to-[#fbb140]"
                              :icon="['fas', 'envelope']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-2.5">Event</span>
                      </a>

                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.form"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex h-13 w-14 content-center items-center text-center">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all duration-150 px-4 py-3.4 hover:p-4.4 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#11cdef] to-[#1171ef]"
                              :icon="['fab', 'wpforms']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-2.5">Form</span>
                      </a>

                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.wedding"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex text-center items-center content-center h-13 w-13">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all duration-150 px-4 py-3.4 hover:p-4.4 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#2dce89] to-[#2dcecc]"
                              :icon="['fas', 'heart']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-2.5">Wedding</span>
                      </a>

                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.appointment"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex text-center items-center content-center h-13 w-13">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all duration-150 px-4 py-3.4 hover:p-4.4 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#8965e0] to-[#bc65e0]"
                              :icon="['fas', 'calendar-check']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-3">Appointment</span>
                      </a>

                      <a
                        :href="'/?questionId=1&siteType=' + SITE_TYPES.leads"
                        class="flex flex-col w-2/6 py-3 text-center items-center content-center"
                      >
                        <div class="flex text-center items-center content-center h-13 w-13">
                          <div class="mx-auto">
                            <font-awesome-icon
                              class="transition-all duration-150 px-4 py-3.4 hover:p-4.4 text-center inline-flex items-center justify-center rounded-full text-white bg-gradient-to-r from-[#ffd600] to-[#beff00]"
                              :icon="['fas', 'bullhorn']"
                              size="lg"
                            />
                          </div>
                        </div>
                        <span class="text-0.8125 text-white font-semibold mt-3">Sales Form</span>
                      </a>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>

        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            :class="{ 'py-1.25 px-4 md:px-7.5 lg:px-6.2': !route.meta.isFullWidthLayout }"
          />
        </router-view>

        <div class="w-full py-8 mx-auto px-4 md:px-7.5 lg:px-7.15">
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import useStore from 'store'
import { HomeFilled, CirclePlusFilled } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import { SITE_TYPES } from 'core/constants'

export default defineComponent({
  name: 'Layout',
  components: {
    HomeFilled,
    CirclePlusFilled,
  },

  setup() {
    const route: any = useRoute()
    const store = useStore()
    const isSBPin = computed<boolean>(() => store.dashboard.isSBPin)
    const loading = computed(() => store.global.loading)
    const clickIconMenu = ref(false)

    const setIsSBPin = (b: boolean) => store.dashboard.setIsSBPin(b)
    return {
      isSBPin,
      loading,
      clickIconMenu,
      setIsSBPin,
      route,
      store,
      SITE_TYPES,
    }
  },
})
</script>

<style scoped>
.main-content {
  @apply transition-all duration-300;
}
@media screen and (max-width: 1023px) {
  .main-content {
    margin-left: 0 !important;
  }
}
</style>

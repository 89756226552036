import { defineStore } from 'pinia';
import { ILogin } from './types';
import { AUTH_TOKEN, AUTH_USER, BASE_URL } from 'core/constants';
import { useState } from './state'; // Import your store state

export const useActions = defineStore('auth.actions', () => {
  const state = useState(); // Access the store state

  const makeRequest = <T>(
    endpoint: string,
    data: any,
    method: 'GET' | 'POST'
  ): Promise<T> => {
    const token = state.token;
    let headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    const requestOptions: RequestInit = {
      method: method,
      headers: headers,
    };
  
    if (method === 'POST') {
      requestOptions.body = JSON.stringify(data);
    }
  
    return new Promise<T>((resolve, reject) => {
      fetch(BASE_URL + endpoint, requestOptions)
        .then((response) => {
          if (!response.ok) {
            reject(new Error('Request failed'));
          }
          return response.json();
        })
        .then((jsonData) => {
          resolve(jsonData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  const actLogin = async (token: any, user: any) => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN, token);
      state.isAuthenticated = true;
    }
    if (user) {
      localStorage.setItem(AUTH_USER, JSON.stringify(user));
      state.user = user;
    }
  };

  const actLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_USER);
    state.isAuthenticated = false;
    state.user = null;
    window.location.href = '/login';
  };

  return {
    actLogin,
    actLogout,
    makeRequest
  };
});

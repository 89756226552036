import env from 'core/env'
export const PAGE_SIZE = env('VITE_PAGE_SIZE', 10)
export const AUTH_TOKEN = 'venlyToken'
export const AUTH_USER = 'venlyUser'
export const AUTH_EMAIL = 'venlyEmail'
export const BASE_URL = 'https://api.venia.cloud/api/v1/'
export const SITE_TYPES = {
    wedding: 1,
    business: 2,
    event: 3,
    appointment: 4,
    form: 5,
    leads: 6,
}

import { ThumbUpIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/vue/solid'
import dayjs from 'dayjs'
import {  h } from 'vue'
import { ElMessage } from 'element-plus'

export const formatDate = (date: Date, format: string = 'hh:mm') => {
  return dayjs(date).format(format)
}

export const checkIsMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const formatNumber = (number: number | bigint) => {
  return new Intl.NumberFormat().format(number)
}

export const sendNotification = (type: string, msg: string) : void => {
  let title = "Notification";
  
  let customClass = 'info';
  let customIcon = InformationCircleIcon;
  switch (type) {
    case 'success':
      title = "Success";
      customClass = 'success'
      customIcon = ThumbUpIcon
      break;
    case 'error':
      title = "Failed";
      customClass = 'danger'
      customIcon = ExclamationIcon
      break;
    case 'info':
      customClass = 'info'
      customIcon = InformationCircleIcon
      break;
    case 'warning':
      title = "Warning";
      customClass = 'warning'
      customIcon = ExclamationIcon
      break;
  }

  ElMessage.success({
    message: h('div', null, [
      h('p', { class: 'message-header' }, ' '+title),
      h('p', { class: 'message-footer' }, msg),
    ]),
    showClose: true,
    type: type,
    icon: customIcon,
    customClass: customClass,
  })
}